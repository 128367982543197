<template>
  <div>
    <ChatTransitionSettings
      name="survey"
      :key="key"
      :has-survey="true"
      :title="lang.chatTransitionSettings.tabs.survey[languageSelected]"
      :titleClass="'text-danger'"
      :integration="true"
      :params="sendSurveySettings"
      ref="surveySettings"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'IntegrationSurvey',
  components: {
    ChatTransitionSettings: () =>
      import(
        '../../../../../../attention-requests-settings/chat-transition/ChatTransitionSettings.vue'
      )
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'userMessageInEdit',
      'botMessageInEdit',
      'dialogsMap'
    ])
  },
  methods: {
    closeIntegrationsPopup() {
      this.$emit('close', true)
    },
    open() {
      this.loadSurvey()
    },
    loadSurvey() {
      const dialogId = this.userMessageInEdit.isEditing
        ? this.userMessageInEdit.dialogId
        : this.botMessageInEdit.dialogId

      if (
        dialogId &&
        this.dialogsMap[dialogId] &&
        this.dialogsMap[dialogId].sendSurveySettings
      ) {
        // currently only one integration is available, but will be multiple in the future
        const dialogObj = this.dialogsMap[dialogId]
        this.sendSurveySettings = dialogObj.sendSurveySettings
      } else {
        this.sendSurveySettings = {}
      }
      this.key = new Date().getTime()
    },
    getValue() {
      return this.$refs.surveySettings.getValue(true)
    }
  },
  data() {
    return {
      key: new Date().getTime(),
      sendSurveySettings: {}
    }
  },
  mounted() {
    this.sendSurveySettings = {}
  }
}
</script>
